<script>
export default {
  data() {
    return {
      title: "Nexera",
    };
  },
  mounted() {
    window.addEventListener("scroll", (a) => {
      a.preventDefault();
      this.bindEvents();
    });
    var lang = window.sessionStorage.getItem("lang");
    if (lang) {
      this.$i18n.locale = lang;
    } else {
      this.$i18n.locale = "tr";
    }
    var foundPlugin = document.getElementById("pluginjs");
    var foundTheme = document.getElementById("themejs");
    if (foundPlugin && foundTheme) {
      window.location.reload();
    }
  },
  created() {},
  components: {},
  methods: {
    bindEvents() {
      document.getElementById("langtr").addEventListener("click", (a) => {
        a.preventDefault();
        this.changeLang("tr");
      });
      document.getElementById("langen").addEventListener("click", (a) => {
        a.preventDefault();
        this.changeLang("en");
      });
    },
    changeLang(lang) {
      window.sessionStorage.setItem("lang", lang);
      //this.$i18n.locale = lang;
      window.location.reload();
    },
  },
};
</script>

<template>
  <div>
    <header class="wrapper bg-soft-primary">
      <nav class="navbar navbar-expand-lg center-nav transparent navbar-light">
        <div class="container flex-lg-row flex-nowrap align-items-center">
          <div class="navbar-brand w-100">
            <a class="nav-link scroll" href="#home">
              <img
                src="../assets/img/logo-title.png"
                class="logo-img"
                srcset="../assets/img/logo-title.png"
                alt=""
              />
            </a>
          </div>
          <div class="navbar-collapse offcanvas-nav">
            <div class="offcanvas-header d-lg-none d-xl-none">
              <a class="nav-link scroll" href="#home"
                ><img
                  src="../assets/img/logo-title.png"
                  class="logo-img"
                  srcset="../assets/img/logo-title.png"
                  alt=""
              /></a>
              <button
                type="button"
                class="
                  btn-close btn-close-white
                  offcanvas-close offcanvas-nav-close
                "
                aria-label="Close"
              ></button>
            </div>
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link scroll" href="#home">{{
                  $t("menu.home")
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link scroll" href="/home#about">{{
                  $t("menu.about")
                }}</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#">{{
                  $t("menu.solutions")
                }}</a>
                <ul class="dropdown-menu">
                  <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#">{{
                      $t("menu.product-management")
                    }}</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/smart-data-management"
                        >
                          {{ $t("menu.smart-data-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/model-technical-information"
                        >
                          {{ $t("menu.model-technical") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link class="dropdown-item" to="/model-flow">
                          {{ $t("menu.operation-flow-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/product-barcode"
                        >
                          {{ $t("menu.product-barcode") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/quality-management"
                        >
                          {{ $t("menu.quality-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/laboratory-management"
                        >
                          {{ $t("menu.laboratory-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/cuttingroom-management"
                        >
                          {{ $t("menu.cuttingroom-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/warehouse-management"
                        >
                          {{ $t("menu.warehouse-management") }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <router-link
                      class="dropdown-item"
                      to="/competency-management"
                    >
                      {{ $t("menu.competency-management") }}
                    </router-link>
                  </li>
                  <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#">{{
                      $t("menu.shopfloor-management")
                    }}</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/digital-value-streammap"
                        >
                          {{ $t("menu.digital-value-streammap") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/stock-management"
                        >
                          {{ $t("menu.stock-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/smart-line-balancing"
                        >
                          {{ $t("menu.smart-line-balancing") }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <router-link class="dropdown-item" to="/machine-management">
                      {{ $t("menu.machine-management") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      class="dropdown-item"
                      to="/visual-shopfloor-management"
                    >
                      {{ $t("menu.visual-shopfloor-management") }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#">{{
                  $t("menu.services")
                }}</a>
                <ul class="dropdown-menu">
                  <!-- <li class="nav-item">
                    <a class="dropdown-item" href="./projects.html"
                      >Digital Transformation - MES Solutions</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="dropdown-item" href="./projects2.html"
                      >ERP Consulting</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="dropdown-item" href="./projects3.html"
                      >Big Data Analyses</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="dropdown-item" href="./projects4.html"
                      >Scrum-Agile Management Consulting</a
                    >
                  </li> -->
                  <li class="dropdown">
                    <a class="dropdown-item scroll" href="#services">{{
                      $t("menu.digital-maturity")
                    }}</a>
                  </li>
                </ul>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link scroll" href="#testimonials">{{
                  $t("menu.testimonials")
                }}</a>
              </li> -->
              <!-- <li class="nav-item">
                <a class="nav-link" href="#!">Career</a>
              </li> -->
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">
                  {{ $t("menu.contact") }}
                </router-link>
              </li>
            </ul>
            <!-- /.navbar-nav -->
          </div>
          <!-- /.navbar-collapse -->
          <div class="navbar-other d-flex ms-auto">
            <ul
              class="navbar-nav flex-row align-items-center ms-auto"
              data-sm-skip="true"
            >
              <li class="nav-item dropdown language-select text-uppercase">
                <a
                  class="nav-link dropdown-item dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >{{ $i18n.locale }}</a
                >
                <ul class="dropdown-menu">
                  <li
                    class="nav-item"
                    id="langtr"
                    ref="langtr"
                    @click="changeLang('tr')"
                  >
                    <a class="dropdown-item" @click="changeLang('tr')" href="#"
                      >Tr</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    id="langen"
                    ref="langen"
                    @click="changeLang('en')"
                  >
                    <a class="dropdown-item" @click="changeLang('en')" href="#"
                      >En</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item d-lg-none">
                <div class="navbar-hamburger">
                  <button
                    class="hamburger animate plain"
                    data-toggle="offcanvas-nav"
                  >
                    <span></span>
                  </button>
                </div>
              </li>
            </ul>
            <!-- /.navbar-nav -->
          </div>
          <!-- /.navbar-other -->
        </div>
        <!-- /.container -->
      </nav>
      <!-- /.navbar -->
    </header>
    <section id="home" class="wrapper g-gradient-primary">
      <div class="wrapper bg-gradient-primary">
        <div class="container pt-10 pt-md-14">
          <div class="row gx-2 gy-10 align-items-center">
            <div
              class="
                col-md-10
                offset-md-1 offset-lg-0
                col-lg-6
                text-center text-lg-start
                order-2 order-lg-0
              "
              data-cues="slideInDown"
              data-group="page-title"
              data-delay="600"
            >
              <h1 class="display-1 mb-5 mx-md-10 mx-lg-0">
                {{ $t("home.main") }}<br /><span
                  class="typer text-primary text-nowrap"
                  data-delay="100"
                  data-words="Production Management.,Competency Management.,Shopfloor Flow Management.,Machine Management.,Visual Shopfloor Management."
                ></span
                ><span class="cursor text-primary" data-owner="typer"></span>
              </h1>
              <p class="lead fs-23 mb-7">
                {{ $t("home.second") }}
              </p>
              <div
                class="
                  d-flex
                  justify-content-center justify-content-lg-start
                  mb-4
                "
                data-cues="slideInDown"
                data-group="page-title-buttons"
                data-delay="900"
              >
                <span
                  ><a
                    class="btn btn-lg btn-primary rounded-pill me-2 scroll"
                    href="#solutions"
                    >{{ $t("home.check-solutions") }}</a
                  ></span
                >
              </div>
            </div>
            <div class="col-lg-4 ms-auto position-relative">
              <div class="row g-1">
                <div
                  class="col-12 d-flex flex-column ms-auto"
                  data-cues="fadeIn"
                  data-group="col-start"
                  data-delay="900"
                >
                  <div class="ms-auto">
                    <img
                      class="img-fluid rounded shadow-lg"
                      src="../assets/img/logo-purple.png"
                      srcset="../assets/img/logo.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about" class="wrapper bg-gradient-primary">
      <div class="container py-14 py-md-16">
        <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div class="col-md-8 col-lg-6 col-xl-6 order-lg-2 position-relative">
            <div
              class="shape bg-soft-primary rounded-circle rellax w-20 h-20"
              data-rellax-speed="1"
              style="top: -2rem; right: -1.9rem"
            ></div>
            <figure class="rounded">
              <img
                src="../assets/img/photos/about.png"
                srcset="../assets/img/photos/about.png"
                alt=""
              />
            </figure>
          </div>
          <!--/column -->
          <div class="col-lg-6">
            <h2 class="display-4 mb-3">{{ $t("about.main") }}</h2>
            <p class="lead fs-lg">
              {{ $t("about.second") }}
            </p>
            <div class="row gx-xl-10 gy-6">
              <!--/column -->
            </div>
            <!--/.row -->
          </div>
          <!--/column -->
        </div>
        <!--/.row -->
      </div>
      <!-- /.container -->
    </section>
    <section id="solutions" class="wrapper bg-gradient-primary">
      <div class="container py-14 py-md-16 text-center">
        <div class="row">
          <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <h2 class="fs-15 text-uppercase text-muted mb-3">
              {{ $t("solutions.what-we-do") }}
            </h2>
            <h3 class="display-4 mb-10 px-xl-10">
              {{ $t("solutions.main") }}
            </h3>
          </div>
          <!-- /column -->
        </div>
        <!-- /.row -->
        <div class="position-relative">
          <div class="row gx-md-5 gy-5 text-center">
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/search-1.svg"
                    class="svg-inject icon-svg icon-svg-md text-yellow mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.smart-data-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.sdm-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-yellow"
                    to="/smart-data-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/browser.svg"
                    class="svg-inject icon-svg icon-svg-md text-red mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.model-technical") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.model-technical-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-red"
                    to="/model-technical-information"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/chat-2.svg"
                    class="svg-inject icon-svg icon-svg-md text-green mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.operation-flow-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.operation-flow-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-green"
                    to="/model-flow"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/megaphone.svg"
                    class="svg-inject icon-svg icon-svg-md text-blue mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.product-barcode") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.product-barcode-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-blue"
                    to="/product-barcode"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/check-list.svg"
                    class="svg-inject icon-svg icon-svg-md text-red mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.quality-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.quality-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-red"
                    to="/product-barcode"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/analytics.svg"
                    class="svg-inject icon-svg icon-svg-md text-blue mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.laboratory-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.laboratory-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-blue"
                    to="/laboratory-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/tie.svg"
                    class="svg-inject icon-svg icon-svg-md text-green mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.cuttingroom-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.cuttingroom-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-green"
                    to="/cuttingroom-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/shop.svg"
                    class="svg-inject icon-svg icon-svg-md text-yellow mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.warehouse-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.warehouse-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-yellow"
                    to="/warehouse-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/pie-chart.svg"
                    class="svg-inject icon-svg icon-svg-md text-green mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.competency-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.competency-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-green"
                    to="/competency-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/price-tag.svg"
                    class="svg-inject icon-svg icon-svg-md text-red mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.digital-value-streammap") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.digital-value-streammap-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-red"
                    to="/digital-value-streammap"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/calculator.svg"
                    class="svg-inject icon-svg icon-svg-md text-yellow mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.stock-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.stock-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-yellow"
                    to="/stock-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/light-bulb.svg"
                    class="svg-inject icon-svg icon-svg-md text-blue mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.smart-line-balancing") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.smart-line-balancing-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-blue"
                    to="/smart-line-balancing"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/television.svg"
                    class="svg-inject icon-svg icon-svg-md text-yellow mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.machine-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.machine-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-yellow"
                    to="/machine-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card shadow-lg">
                <div class="card-body">
                  <img
                    src="../assets/img/icons/pictures.svg"
                    class="svg-inject icon-svg icon-svg-md text-green mb-3"
                    alt=""
                  />
                  <h4>{{ $t("menu.visual-shopfloor-management") }}</h4>
                  <p class="mb-2">
                    {{ $t("solutions.visual-shopfloor-management-desc") }}
                  </p>
                  <router-link
                    class="more hover scroll link-green"
                    to="/visual-shopfloor-management"
                  >
                    {{ $t("menu.learn-more") }}</router-link
                  >
                </div>
                <!--/.card-body -->
              </div>
              <!--/.card -->
            </div>
            <!--/column -->
          </div>
          <!--/.row -->
        </div>
        <!-- /.position-relative -->
      </div>
      <!-- /.container -->
    </section>

    <section id="services">
      <div class="wrapper bg-gradient-primary">
        <div class="container pt-10 pt-md-14">
          <div class="row gx-2 gy-10 align-items-center">
            <div
              class="
                col-md-10
                offset-md-1 offset-lg-0
                col-lg-6
                text-center text-lg-start
                order-2 order-lg-0
              "
              data-cues="slideInDown"
              data-group="page-title"
              data-delay="600"
            >
              <h1 class="display-1 mb-5 mx-md-10 mx-lg-0">
                {{ $t("services.main") }}
              </h1>
              <p class="lead fs-23 mb-7">
                {{ $t("services.second") }}
              </p>
              <div
                class="
                  d-flex
                  justify-content-center justify-content-lg-start
                  mb-4
                "
                data-cues="slideInDown"
                data-group="page-title-buttons"
                data-delay="900"
              ></div>
            </div>
            <div class="col-lg-5 ms-auto position-relative">
              <div class="row g-1">
                <div
                  class="col-12 d-flex flex-column ms-auto"
                  data-cues="fadeIn"
                  data-group="col-start"
                  data-delay="900"
                >
                  <div class="ms-auto">
                    <img
                      class="img-fluid rounded shadow-lg"
                      src="../assets/img/services-main.png"
                      srcset="../assets/img/services-main.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="testimonials"
      class="
        wrapper
        image-wrapper
        bg-auto
        no-overlay
        bg-image
        text-center
        bg-map
      "
      data-image-src="../assets/img/map.png"
    >
      <div class="container py-14 pt-md-16 pb-md-18">
        <div class="row pt-md-12">
          <div class="col-lg-10 col-xl-9 col-xxl-8 mx-auto">
            <h2 class="fs-15 text-uppercase text-muted mb-3">
              {{ $t("testimonials.main") }}
            </h2>
            <h3 class="display-4 mb-8 px-lg-12">
              {{ $t("testimonials.second") }}
            </h3>
          </div>
          <!-- /.row -->
        </div>
        <!-- /column -->
        <div class="row pb-md-12">
          <div class="col-md-10 col-lg-9 col-xl-7 mx-auto">
            <div class="row align-items-center counter-wrapper gy-4 gy-md-0">
              <div class="col-md-3 text-center">
                <h3 class="counter counter-lg text-primary">23</h3>
                <p>{{ $t("testimonials.companies") }}</p>
              </div>
              <!--/column -->
              <div class="col-md-3 text-center">
                <h3 class="counter counter-lg text-primary">14</h3>
                <p>{{ $t("testimonials.modules") }}</p>
              </div>
              <!--/column -->
              <div class="col-md-3 text-center">
                <h3 class="counter counter-lg text-primary">15</h3>
                <p>{{ $t("testimonials.years") }}</p>
              </div>
              <div class="col-md-3 text-center">
                <h3 class="counter counter-lg text-primary">150</h3>
                <p>{{ $t("testimonials.digital-maturity") }}</p>
              </div>
              <!--/column -->
            </div>
            <!--/.row -->
          </div>
          <!-- /column -->
        </div>
        <!-- /.row -->
      </div>
      <!--testimonials-->
      <!-- <h2 class="fs-15 text-uppercase text-muted text-center mb-8">
        {{ $t("testimonials.customers") }}
      </h2> -->
      <!-- <div
        class="carousel owl-carousel clients mb-0"
        data-margin="30"
        data-loop="true"
        data-dots="false"
        data-autoplay="true"
        data-autoplay-timeout="3000"
        data-responsive='{"0":{"items": "2"}, "768":{"items": "4"}, "992":{"items": "5"}, "1200":{"items": "6"}, "1400":{"items": "7"}}'
      >
        <div class="item px-5">
          <img src="../assets/img/brands/c1.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c2.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c3.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c4.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c5.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c6.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c7.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c8.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c9.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c10.png" alt="" />
        </div>
        <div class="item px-5">
          <img src="../assets/img/brands/c11.png" alt="" />
        </div>
      </div> -->
      <!-- /.container -->
    </section>
    <!-- /section -->
    <!--Clients -->

    <!--   <section id="contact" class="wrapper bg-light">
      <div class="container py-14 py-md-16 text-center">
        <div class="row">
          <div class="col-md-9 col-lg-7 col-xl-7 mx-auto text-center">
            <img
              src="../assets/img/icons/puzzle-2.svg"
              class="svg-inject icon-svg icon-svg-md mb-4 text-blue"
              alt=""
            />
            <h2 class="display-4 mb-3">{{ $t("contact.main") }}</h2>
            <div
              class="d-flex justify-content-center justify-content-center mb-4"
              data-cues="slideInDown"
              data-group="page-title-buttons"
              data-delay="900"
            >
              <span
                ><a
                  class="btn btn-lg btn-primary rounded-pill me-2 scroll"
                  href="#demos"
                  >{{ $t("contact.yes-of-course") }}</a
                ></span
              >
            </div>
          </div>
        </div>

      </div>
    </section> -->
    <footer class="bg-dark text-inverse">
      <div class="container pt-13 pt-md-15 pb-7">
        <div class="row gy-6 gy-lg-0">
          <div class="col-lg-4">
            <div class="widget">
              <h3 class="h2 mb-3 text-white">{{ $t("contact.contact-us") }}</h3>
              <p class="lead mb-5">
                {{ $t("contact.footer") }}
              </p>
              <a href="/contact" class="btn btn-white rounded-pill">{{
                $t("contact.lets-do")
              }}</a>
            </div>
            <!-- /.widget -->
          </div>
          <div class="col-md-4 col-lg-6"></div>
          <!-- /column -->
          <div class="col-md-4 col-lg-2">
            <div class="widget">
              <h4 class="widget-title text-white mb-3">
                {{ $t("contact.where-we-are") }}
              </h4>
              <address>
                {{ $t("contact.address") }}
              </address>
              <a href="mailto:info@nexera.solutions">info@nexera.solutions</a
              ><br />
              <!-- +00 (123) 456 78 90 -->
              <a href="tel:+905539790605">+90 553 979 06 05</a>
            </div>
            <!-- /.widget -->
          </div>
          <!-- /column -->
        </div>
        <!--/.row -->
        <hr class="mt-13 mt-md-15 mb-7" />
        <div class="d-md-flex align-items-center justify-content-between">
          <p class="mb-2 mb-lg-0">
            © 2021 Nexera Solutions. All rights reserved.
            <strong>
              <a
                href="https://www.privacypolicies.com/live/ce295d68-0363-43db-8ff1-8109e73bae89"
                class="hover"
                target="_blank"
                >{{ $t("contact.terms-and-policy") }}</a
              >
            </strong>
          </p>
          <nav class="nav social social-white text-md-end">
            <a href="/home"><i class="uil uil-home"></i></a>

            <a
              href="https://www.linkedin.com/company/nexera-solutions/"
              target="_blank"
              ><i class="uil uil-linkedin"></i
            ></a>
          </nav>
          <!-- /.social -->
        </div>
        <!-- /div -->
      </div>
      <!-- /.container -->
      <div class="progress-wrap">
        <svg
          class="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </footer>
  </div>
</template>

<style>
#about {
  margin-top: 30px !important;
}

#solutions .card {
  height: 430px;
}
</style>

