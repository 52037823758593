
<script>
export default {
  data() {
    return {
      title: "Nexera",
      scripts: [],
    };
  },
  mounted() {
    var lang = window.sessionStorage.getItem("lang");
    if (lang) {
      this.$i18n.locale = lang;
    } else {
      this.$i18n.locale = "tr";
    }
  },
  created() {},
  components: {},
  methods: {
    changeLang(lang) {
      window.sessionStorage.setItem("lang", lang);
      //this.$i18n.locale = lang;
      window.location.reload();
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <header class="wrapper bg-soft-primary">
      <nav class="navbar navbar-expand-lg center-nav transparent navbar-light">
        <div class="container flex-lg-row flex-nowrap align-items-center">
          <div class="navbar-brand w-100">
            <router-link to="/home" class="nav-link">
              <img
                src="../assets/img/logo-title.png"
                class="logo-img"
                srcset="../assets/img/logo-title.png"
                alt=""
              />
            </router-link>
          </div>
          <div class="navbar-collapse offcanvas-nav">
            <div class="offcanvas-header d-lg-none d-xl-none">
              <router-link to="/home" class="nav-link">
                <img
                  src="../assets/img/logo-title.png"
                  class="logo-img"
                  srcset="../assets/img/logo-title.png"
                  alt=""
                />
              </router-link>
              <button
                type="button"
                class="
                  btn-close btn-close-white
                  offcanvas-close offcanvas-nav-close
                "
                aria-label="Close"
              ></button>
            </div>
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link class="nav-link" to="/home">
                  {{ $t("menu.home") }}</router-link
                >
              </li>
                <li class="nav-item">
                <a class="nav-link scroll" href="/home#about">{{
                  $t("menu.about")
                }}</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#">{{
                  $t("menu.solutions")
                }}</a>
                <ul class="dropdown-menu">
                  <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#">{{
                      $t("menu.product-management")
                    }}</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/smart-data-management"
                        >
                          {{ $t("menu.smart-data-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/model-technical-information"
                        >
                          {{ $t("menu.model-technical") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link class="dropdown-item" to="/model-flow">
                          {{ $t("menu.operation-flow-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/product-barcode"
                        >
                          {{ $t("menu.product-barcode") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/quality-management"
                        >
                          {{ $t("menu.quality-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/laboratory-management"
                        >
                          {{ $t("menu.laboratory-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/cuttingroom-management"
                        >
                          {{ $t("menu.cuttingroom-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/warehouse-management"
                        >
                          {{ $t("menu.warehouse-management") }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <router-link
                      class="dropdown-item"
                      to="/competency-management"
                    >
                      {{ $t("menu.competency-management") }}
                    </router-link>
                  </li>
                  <li class="dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#">{{
                      $t("menu.shopfloor-management")
                    }}</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/digital-value-streammap"
                        >
                          {{ $t("menu.digital-value-streammap") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/stock-management"
                        >
                          {{ $t("menu.stock-management") }}
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="dropdown-item"
                          to="/smart-line-balancing"
                        >
                          {{ $t("menu.smart-line-balancing") }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <router-link class="dropdown-item" to="/machine-management">
                      {{ $t("menu.machine-management") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      class="dropdown-item"
                      to="/visual-shopfloor-management"
                    >
                      {{ $t("menu.visual-shopfloor-management") }}
                    </router-link>
                  </li>
                </ul>
              </li>
   <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#">{{
                  $t("menu.services")
                }}</a>
                <ul class="dropdown-menu">
                  <!-- <li class="nav-item">
                    <a class="dropdown-item" href="./projects.html"
                      >Digital Transformation - MES Solutions</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="dropdown-item" href="./projects2.html"
                      >ERP Consulting</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="dropdown-item" href="./projects3.html"
                      >Big Data Analyses</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="dropdown-item" href="./projects4.html"
                      >Scrum-Agile Management Consulting</a
                    >
                  </li> -->
                  <li class="dropdown">
                    <a class="dropdown-item scroll" href="/home#services">{{
                      $t("menu.digital-maturity")
                    }}</a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">
                  {{ $t("menu.contact") }}
                </router-link>
              </li>
            </ul>
            <!-- /.navbar-nav -->
          </div>
          <!-- /.navbar-collapse -->
          <div class="navbar-other d-flex ms-auto">
            <ul
              class="navbar-nav flex-row align-items-center ms-auto"
              data-sm-skip="true"
            >
              <li class="nav-item d-lg-none">
                <div class="navbar-hamburger">
                  <button
                    class="hamburger animate plain"
                    data-toggle="offcanvas-nav"
                  >
                    <span></span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <!-- /.navbar-other -->
        </div>
        <!-- /.container -->
      </nav>
      <!-- /.navbar -->
    </header>
    <router-view :key="$route.fullPath" />
    <footer class="bg-dark text-inverse">
      <div class="container pt-13 pt-md-15 pb-7">
        <div class="row gy-6 gy-lg-0">
          <div class="col-lg-4">
            <div class="widget">
              <h3 class="h2 mb-3 text-white">{{ $t("contact.contact-us") }}</h3>
              <p class="lead mb-5">
                {{ $t("contact.footer") }}
              </p>
              <a href="/contact" class="btn btn-white rounded-pill">{{
                $t("contact.lets-do")
              }}</a>
            </div>
            <!-- /.widget -->
          </div>
          <div class="col-md-4 col-lg-6"></div>
          <!-- /column -->
          <div class="col-md-4 col-lg-2">
            <div class="widget">
              <h4 class="widget-title text-white mb-3">
                {{ $t("contact.where-we-are") }}
              </h4>
              <address>
                {{ $t("contact.address") }}
              </address>
              <a href="mailto:info@nexera.solutions">info@nexera.solutions</a
              ><br />
              
              <a href="tel:+905539790605">+90 553 979 06 05</a>
              <!-- +00 (123) 456 78 90 -->
            </div>
            <!-- /.widget -->
          </div>
          <!-- /column -->
        </div>
        <!--/.row -->
        <hr class="mt-13 mt-md-15 mb-7" />
        <div class="d-md-flex align-items-center justify-content-between">
          <p class="mb-2 mb-lg-0">
            © 2021 Nexera Solutions. All rights reserved.
            <strong>
              <a
                href="https://www.privacypolicies.com/live/ce295d68-0363-43db-8ff1-8109e73bae89"
                class="hover"
                target="_blank"
                >{{ $t("contact.terms-and-policy") }}</a
              >
            </strong>
          </p>
          <nav class="nav social social-white text-md-end">
            <a href="/home"><i class="uil uil-home"></i></a>
            <a
              href="https://www.linkedin.com/company/nexera-solutions/"
              target="_blank"
              ><i class="uil uil-linkedin"></i
            ></a>
          </nav>
          <!-- /.social -->
        </div>
        <!-- /div -->
      </div>
      <!-- /.container -->
    </footer>
    <div class="progress-wrap">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
  </div>
</template>

<style>
.logo-img {
  width: 200px;
}
</style>

