
<script>
import CookieLaw from "vue-cookie-law";
export default {
  data() {
    return {
      title: "Nexera",
      scripts: [],
    };
  },
  mounted() {
    var lang = window.sessionStorage.getItem("lang");
    if (lang) {
      this.$i18n.locale = lang;
    } else {
      this.$i18n.locale = "en";
    }
    var foundPlugin = document.getElementById("pluginjs");
    var foundTheme = document.getElementById("themejs");
    if (foundPlugin && foundTheme) {
      window.location.reload();
    }

    let pluginJs = document.createElement("script");

    pluginJs.setAttribute("src", "./theme/js/plugins.js");
    pluginJs.setAttribute("id", "pluginjs");
    pluginJs.setAttribute("defer", "");

    pluginJs.onload  = function(){
    let themeJs = document.createElement("script");

    themeJs.setAttribute("src", "./theme/js/theme.js");
    themeJs.setAttribute("id", "themejs");
    themeJs.setAttribute("defer", "");
      document.body.appendChild(themeJs);
    }

    window.setTimeout(() => {
      document.body.appendChild(pluginJs);
    }, 50);
  },
  created() {},
  components: { CookieLaw },
  methods: {},
};
</script>

<template>
  <div class="content-wrapper">
    <router-view :key="$route.fullPath" />
    <cookie-law theme="blood-orange">
      <div slot-scope="props" style="width: 90%; margin: 0 auto">
        <span style="font-size: 20px; text-align: center; margin: 15px 0"
          >{{ $t("cookie.message") }}
        </span>
        <a
          href="https://www.privacypolicies.com/live/ce295d68-0363-43db-8ff1-8109e73bae89"
          class="hover"
          target="_blank"
          >{{ $t("contact.terms-and-policy") }}</a
        >
        <span
          ><a
            class="btn btn-lg btn-primary rounded-pill me-2 scroll"
            href="#"
            style="float: right"
            @click="props.accept"
            >{{ $t("cookie.accept") }}</a
          ></span
        >
      </div>
    </cookie-law>
  </div>
</template>

<style>
.progress-wrap{
opacity:1;
}
.logo-img {
  width: 200px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

</style>

