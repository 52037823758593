
<script>
export default {
  data() {
    return {
      title: "Nexera-Solutions",
      scripts: [],
    };
  },
  mounted() {},
  created() {},
  components: {},
  methods: {},
};
</script>

<template>
  <section id="snippet-1" class="wrapper bg-light wrapper-border">
    <div class="container pt-5 pt-md-6 pb-3 pb-md-5">
      <div class="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-18 align-items-center">
        <div class="col-lg-6 position-relative">
          <div
            class="shape bg-line leaf rounded-circle rellax w-17 h-17"
            data-rellax-speed="1"
            style="top: -2rem; right: -0.6rem"
          ></div>
          <div
            class="shape bg-pale-violet rounded-circle rellax w-17 h-17"
            data-rellax-speed="1"
            style="bottom: -2rem; left: -0.4rem"
          ></div>
          <figure class="rounded mb-0">
                 <img
                    src="../../assets/img/icons/search-1.svg"
                    class="svg-inject icon-svg icon-svg-md text-yellow mb-3 sub-page-icon-img"
                    alt=""
                  />
          </figure>
        </div>
        <!--/column -->
        <div class="col-lg-6">
          <h3 class="display-4 mb-4">{{ $t("menu.smart-data-management") }}</h3>
          <p class="mb-5">{{ $t("sdm.desc") }}</p>
          <div class="row gy-3">
            <div class="col-xl-6">
              <ul class="icon-list bullet-bg bullet-soft-leaf mb-0">
                <li>
                  <span><i class="uil uil-check"></i></span
                  ><span
                    >{{ $t("sdm.func1") }}</span
                  >
                </li>
                <li class="mt-3">
                  <span><i class="uil uil-check"></i></span
                  ><span
                    >{{ $t("sdm.func2") }}</span
                  >
                </li>
                <li class="mt-3">
                  <span><i class="uil uil-check"></i></span
                  ><span
                    >{{ $t("sdm.func3") }}</span
                  >
                </li>
              </ul>
            </div>
            <!--/column -->
            <div class="col-xl-6">
              <ul class="icon-list bullet-bg bullet-soft-leaf mb-0">
                <li>
                  <span><i class="uil uil-check"></i></span
                  ><span
                    >{{ $t("sdm.func4") }}</span
                  >
                </li>
                <li class="mt-3">
                  <span><i class="uil uil-check"></i></span
                  ><span>{{ $t("sdm.func5") }}</span>
                </li>
                   <li class="mt-3">
                  <span><i class="uil uil-check"></i></span
                  ><span>{{ $t("sdm.func6") }}</span>
                </li>
              </ul>
            </div>
            <!--/column -->
          </div>
          <!--/.row -->
        </div>
        <!--/column -->
      </div>
      <!--/.row -->

      <!--/.row -->
    </div>
  </section>
</template>

<style>
.sub-page-icon-img{
    width:60%!important;
    height:auto!important;
    margin:0 20%!important;
}
</style>

