
<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import config from '../../public/config.json'

export default {
  data() {
    return {
      title: "Nexera-Solutions",
      scripts: [],
      form: {
        name: "",
        email: "",
        message: "",
        accept: false,
      },
      apiUrl: config.apiUrl + "/contactmessage",
      verify: "",
    };
  },
  mounted() {},
  created() {},
  components: { VueRecaptcha },
  methods: {
    onVerify: function (response) {
      this.verify = response;
    },
    onExpired: function () {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset();
      this.verify = "";
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    sendMessage() {
      if (!this.form.name || !this.form.email || !this.form.message) {
        this.$toast.error(this.$t("contact.form-required-message"));
        return;
      }
      if (!this.validateEmail(this.form.email)) {
        this.$toast.error(this.$t("contact.form-email-message"));
        return;
      }
      if (!this.form.accept) {
        this.$toast.error(this.$t("contact.form-accept-policy"));
        return;
      }
      if (!this.verify) {
        this.$toast.error(this.$t("contact.form-verify"));
        return;
      }
      this.postMessage();
    },

    postMessage() {
      axios
        .post(this.apiUrl, this.form)
        .then((response) => {
          if (response.status == 200) {
            this.resetRecaptcha();
            this.$toast.success(this.$t("contact.form-success"));
            this.form = {
              name: "",
              email: "",
              message: "",
              accept: false,
            };
          } else {
            this.$toast.error(this.$t("contact.form-error"));
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error(this.$t("contact.form-error"));
          this.resetRecaptcha();
        });
    },
  },
};
</script>

<template>
  <section class="wrapper bg-light">
    <div class="container py-14 py-md-16">
      <div class="row">
        <div class="col-xl-10 mx-auto">
          <div class="row gy-10 gx-lg-8 gx-xl-12">
            <div class="col-lg-8">
              <form class="contact-form needs-validation">
                <div class="messages"></div>
                <div class="row gx-4">
                  <div class="col-md-6">
                    <div class="form-floating mb-4">
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        class="form-control"
                        required
                        v-model="form.name"
                      />
                      <label for="form_name"
                        >{{ $t("contact.form-name") }} *</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating mb-4">
                      <input
                        id="form_email"
                        type="text"
                        name="email"
                        class="form-control"
                        required
                        v-model="form.email"
                      />
                      <label for="form_email">E-mail *</label>
                    </div>
                  </div>
                  <!-- /column -->
                  <!-- /column -->

                  <!-- /column -->
                  <!-- /column -->
                  <div class="col-12">
                    <div class="form-floating mb-4">
                      <textarea
                        id="form_message"
                        name="message"
                        class="form-control"
                        style="height: 150px"
                        required
                        v-model="form.message"
                      ></textarea>
                      <label for="form_message"
                        >{{ $t("contact.form-message") }} *</label
                      >
                    </div>
                  </div>
                  <!-- /column -->
                  <div class="col-12">
                    <div class="form-check mb-4">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="invalidCheck"
                        required
                        v-model="form.accept"
                      />
                      <label class="form-check-label" for="invalidCheck">
                        {{ $t("contact.form-agree") }}
                        <a
                          href="https://www.privacypolicies.com/live/ce295d68-0363-43db-8ff1-8109e73bae89"
                          class="hover"
                          target="_blank"
                          >{{ $t("contact.terms-and-policy") }}</a
                        >.
                      </label>
                    </div>
                  </div>
                  <!-- /column -->
                  <div class="col-12">
                    <vue-recaptcha
                      sitekey="6Lc34O0cAAAAALOAGpIRMEbM81gR40fIB-TjsPK0"
                      ref="invisibleRecaptcha"
                      @verify="onVerify"
                      @expired="onExpired"
                      :loadRecaptchaScript="true"
                    >
                    </vue-recaptcha>

                    <button
                      type="button"
                      class="btn btn-primary rounded-pill btn-send mb-3"
                      style="margin-top: 15px"
                      @click="this.sendMessage"
                    >
                      {{ $t("contact.form-submit") }}
                    </button>
                    <p class="text-muted">
                      <strong>*</strong> {{ $t("contact.form-required") }}
                    </p>
                  </div>
                  <!-- /column -->
                </div>
                <!-- /.row -->
              </form>
              <!-- /form -->
            </div>
            <!--/column -->
            <div class="col-lg-4">
              <div class="d-flex flex-row">
                <div>
                  <div class="icon text-primary fs-28 me-4 mt-n1">
                    <i class="uil uil-location-pin-alt"></i>
                  </div>
                </div>
                <div>
                  <h5 class="mb-1">{{ $t("contact.address-title") }}</h5>
                  <address>{{ $t("contact.address") }}</address>
                </div>
              </div>
              <!-- <div class="d-flex flex-row">
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-phone-volume"></i> </div>
              </div>
              <div>
                <h5 class="mb-1">Phone</h5>
                <p>00 (123) 456 78 90 <br class="d-none d-md-block" />00 (987) 654 32 10</p>
              </div>
            </div> -->
              <div class="d-flex flex-row">
                <div>
                  <div class="icon text-primary fs-28 me-4 mt-n1">
                    <i class="uil uil-envelope"></i>
                  </div>
                </div>
                <div>
                  <h5 class="mb-1">E-mail</h5>
                  <p class="mb-0">
                    <a href="mailto:info@nexera.solutions" class="text-body"
                      >info@nexera.solutions</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <!--/column -->
          </div>
          <!--/.row -->
        </div>
        <!-- /column -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </section>
</template>

<style>
</style>

