import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Index from "../src/pages/index.vue";
import Home from "../src/pages/home.vue";
import Solutions from "../src/pages/solutions.vue";
import SmartData from "../src/pages/solutions/smartdata.vue";
import ModelTechnical from "../src/pages/solutions/modeltechnical.vue";
import ModelFlow from "../src/pages/solutions/modelflow.vue";
import ProductBarcode from "../src/pages/solutions/productbarcode.vue";
import QualityManagement from "../src/pages/solutions/qualitymanagement.vue";
import LaboratoryManagement from "../src/pages/solutions/laboratorymanagement.vue";
import CuttingRoomManagement from "../src/pages/solutions/cuttingroommanagement.vue";
import WarehouseManagement from "../src/pages/solutions/warehousemanagement.vue";
import CompetencyManagement from "../src/pages/solutions/competencymanagement.vue";
import StockManagement from "../src/pages/solutions/stockmanagement.vue";
import DigitalValue from "../src/pages/solutions/digitalvalue.vue";
import SmartLine from "../src/pages/solutions/smartlinebalancing.vue";
import MachineManagement from "../src/pages/solutions/machinemanagement.vue";
import VisualShopfloor from "../src/pages/solutions/visualshopfloor.vue";
import Contact from "../src/pages/contact.vue";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import i18n from "./i18n";

Vue.use(VueRouter);
Vue.use(VueToast,{position:"bottom-right"})
Vue.config.productionTip = true;
const routes = [
  {
    path: "*",
    redirect: "/home",
    component: Index,
    children: [
      { path: "/home", component: Home },
      { path: "/solutions", component: Solutions, children: [
        { path: "/smart-data-management", component: SmartData },        
        { path: "/model-technical-information", component: ModelTechnical },        
        { path: "/model-flow", component: ModelFlow },        
        { path: "/product-barcode", component: ProductBarcode },        
        { path: "/quality-management", component: QualityManagement },        
        { path: "/laboratory-management", component: LaboratoryManagement },        
        { path: "/cuttingroom-management", component: CuttingRoomManagement },        
        { path: "/warehouse-management", component: WarehouseManagement },        
        { path: "/competency-management", component: CompetencyManagement },        
        { path: "/digital-value-streammap", component: DigitalValue },        
        { path: "/stock-management", component: StockManagement },        
        { path: "/smart-line-balancing", component: SmartLine },        
        { path: "/machine-management", component: MachineManagement },        
        { path: "/visual-shopfloor-management", component: VisualShopfloor },        
        { path: "/contact", component: Contact }
      ] },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
